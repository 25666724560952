@charset "UTF-8";
#mapWrapper {
  position: relative;
  overflow: hidden; }

#graphs {
  background: #fff;
  padding: 5px 0 5px 5px;
  position: absolute;
  top: 0;
  right: -33%;
  height: 100%;
  width: 33%;
  transition: right 0.5s linear; }
  #graphs.open {
    right: 0;
    transition: right 0.5s linear; }
  #graphs .close_btn {
    color: #297cc0;
    cursor: pointer;
    float: right;
    line-height: 0.9;
    padding: 0 0 3px;
    text-align: right; }
    #graphs .close_btn:after {
      clear: both;
      content: '';
      display: block; }
  #graphs .scroll_container {
    clear: both;
    max-height: 100%;
    overflow-y: scroll; }
  #graphs .dark_blue {
    color: #0E273B; }

html {
  height: 100%; }

body {
  height: 100%;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.475;
  background: #231f20;
  color: #383838; }

* html #container {
  height: 100%; }

#container {
  padding: 0; }

.row {
  margin: 0; }

body#tinymce {
  background: #fff;
  padding: 10px; }

div {
  border: 0;
  margin: 0;
  padding: 0; }

table {
  border: 0;
  margin: 0;
  padding: 0; }

tr {
  border: 0;
  margin: 0;
  padding: 0; }

th {
  border: 0;
  margin: 0;
  padding: 0; }

td {
  border: 0;
  margin: 0;
  padding: 0; }

form {
  border: 0;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  margin: 0;
  padding: 0; }

h1 {
  position: relative;
  color: #205e91;
  line-height: 1;
  margin: 10px 0;
  padding: 0;
  font-size: 2.2em; }

h2 {
  position: relative;
  color: #2978bb;
  line-height: 1;
  margin: 10px 0;
  padding: 0;
  font-size: 2em; }

h3 {
  position: relative;
  color: #4fa6ed;
  line-height: 1;
  margin: 10px 0;
  padding: 0;
  font-size: 1.8em; }

h4 {
  position: relative;
  color: #205e91;
  line-height: 1;
  margin: 10px 0;
  padding: 0;
  font-size: 1.6em; }

h5 {
  position: relative;
  color: #2978bb;
  line-height: 1;
  margin: 10px 0;
  padding: 0;
  font-size: 1.4em; }

h6 {
  position: relative;
  color: #4fa6ed;
  line-height: 1;
  margin: 10px 0;
  padding: 0;
  font-size: 1.2em; }

p {
  position: relative;
  color: #383838;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  font-size: 1.1em;
  margin: 0 0 10px; }

.page_title {
  border-bottom: 2px solid #000;
  clear: both;
  color: #297cc0;
  font-size: 2.4em;
  padding-bottom: 3px; }

a {
  color: #2978bb;
  text-decoration: none; }
  a:hover {
    color: #4fa6ed;
    text-decoration: none; }
  a:active {
    color: #4fa6ed;
    background-color: transparent; }
  a img {
    border: 0 none; }

hr {
  margin: 10px 0;
  clear: both; }

ul {
  padding-left: 30px;
  overflow: hidden; }

ol {
  padding-left: 30px;
  overflow: hidden; }

li {
  font-size: 1.1em; }

#main.inside li {
  color: #383838;
  line-height: 1.2;
  font-size: 1.1em; }

#tinymce li {
  color: #383838;
  line-height: 1.2;
  font-size: 1.1em; }

.modal-header {
  cursor: move; }

.align_image_left {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2; }
  @media screen and (min-width: 520px) {
    .align_image_left {
      float: left;
      margin: 0 10px 10px 0; } }

.align_image_right {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2; }
  @media screen and (min-width: 520px) {
    .align_image_right {
      float: right;
      margin: 0 0 10px 10px; } }

.table-bordered {
  border: 1px solid #DDD; }

.errmsg {
  color: #C60808;
  font-weight: bold;
  text-shadow: 0 1px #E1E1E1; }

.faux-bullet {
  margin: 0;
  line-height: 1; }

.faux-bullet:before {
  content: '• ';
  display: inline-block;
  width: 15px;
  height: 100%; }

.clear {
  clear: both; }

html {
  height: 100%; }

body.error-page {
  color: #565656;
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
  height: 100%;
  line-height: 1.475; }
  body.error-page #container {
    height: 100%;
    min-height: 100%;
    padding: 0; }
  body.error-page #header {
    background: #fff;
    border-left: 0 none; }
  body.error-page p {
    font-family: 'Raleway', sans-serif; }
  body.error-page #content {
    position: relative; }

.img-responsive {
  display: inline; }

.mid-section {
  background: #fff;
  height: auto; }

.top-content {
  position: relative;
  padding: 0 20px 80px; }
  .top-content p {
    color: #767677;
    font-size: 5.5vw;
    margin: 0;
    padding: 30px 40px 0 0;
    text-align: right; }
    .top-content p:last-child {
      color: #696969;
      font-size: 5.8vw;
      margin: -20px 0 0;
      padding: 0;
      text-align: left; }
  .top-content img {
    height: auto;
    max-width: 100%; }

.bottom-content {
  border-top: 4px solid #4d4d4d;
  padding-top: 2px; }
  .bottom-content p {
    padding: 20px 0;
    font-size: 20px;
    margin: 0;
    text-align: center;
    position: relative; }

.cat {
  display: block;
  position: absolute;
  bottom: 80%;
  right: 0;
  width: 100%; }
  .cat #cat-img {
    height: auto;
    max-width: 100%; }

@media screen and (min-width: 380px) {
  .cat {
    bottom: 75%; } }

@media screen and (min-width: 431px) {
  .cat {
    bottom: 64%; } }

@media screen and (min-width: 464px) {
  .cat {
    bottom: 57%; } }

@media screen and (min-width: 530px) {
  .top-content {
    margin: 0 auto;
    width: 513px; }
    .top-content p {
      font-size: 24px; }
      .top-content p:last-child {
        font-size: 27px; } }

@media screen and (min-width: 1320px) {
  .top-content,
  .bottom-content p {
    width: 480px;
    margin: 0 auto; }
  .top-content {
    padding: 0; }
  .bottom-content p {
    color: #2f322e;
    font-size: 18px;
    text-align: left;
    text-transform: uppercase;
    padding: 0; }
  .cat {
    display: block;
    position: absolute;
    bottom: -2px;
    right: -420px; } }

#page {
  border-top: 5px solid #231f20;
  background: #fff; }

.page_breadcrumb {
  margin: 25px 0; }
  .page_breadcrumb h5 {
    color: #8a8a8a;
    font-size: 0.875em;
    font-weight: normal;
    margin: 10px 0 0; }
    .page_breadcrumb h5:after {
      clear: both;
      content: "";
      display: block; }
    .page_breadcrumb h5 a {
      color: #8a8a8a;
      display: block;
      float: left; }
      .page_breadcrumb h5 a:hover {
        color: #205e91; }

.breadcrumb_divider, .breadcrumb_text, .breadcrumb-divider, .breadcrumb-text {
  display: block;
  float: left;
  margin: -1px 5px 0; }

#header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 92px;
  position: relative; }
  #header .nav_box {
    background: #fff;
    border-bottom: 3px solid #297cc0;
    box-shadow: 0 7px 10px -1px rgba(0, 0, 0, 0.6);
    left: 0;
    min-height: 286px;
    min-width: 100%;
    padding-bottom: 20px;
    position: absolute;
    top: 100%;
    z-index: 700; }
    #header .nav_box .nav_hide {
      display: none;
      position: absolute;
      bottom: 10px;
      right: 50%;
      font-size: 2em;
      color: #297cc0;
      cursor: pointer;
      z-index: 1000; }
    #header .nav_box.show {
      display: block !important;
      visibility: visible !important; }
    #header .nav_box .subNavImg {
      height: auto;
      width: 100%; }
    #header .nav_box .subLink {
      border-bottom: 2px solid #7f7f7f;
      color: #7f7f7f;
      display: block;
      font-weight: bold;
      font-size: 17px;
      padding: 2px 0 0; }
      #header .nav_box .subLink:hover {
        cursor: default;
        text-decoration: none; }

.lower_nav {
  margin: 4px 0 0;
  padding: 0; }
  .lower_nav li {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .lower_nav li a {
      color: #6e6e6e;
      display: block;
      font-size: 15px; }
      .lower_nav li a:hover {
        color: #297cc0;
        text-decoration: none; }

#search_container {
  background: #cfcfcf;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
  padding: 26px 49px; }

.form_field_wrapper {
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15) inset;
  border: 1px solid #b5b5b5;
  position: relative; }
  .form_field_wrapper.has-error {
    border-color: #a94442; }
  .form_field_wrapper input[type="search"] {
    background: transparent;
    border: 0 none;
    color: #b5b5b5;
    font-size: 20px;
    height: 45px;
    margin: 0;
    padding: 0 5px;
    width: 90%; }
    .form_field_wrapper input[type="search"]:focus {
      outline: none; }
    .form_field_wrapper input[type="search"]::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 20px;
      font-style: italic; }
    .form_field_wrapper input[type="search"]::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 20px;
      font-style: italic; }
    .form_field_wrapper input[type="search"]:-ms-input-placeholder {
      /* IE 10+ */
      font-size: 20px;
      font-style: italic; }
    .form_field_wrapper input[type="search"]:-moz-placeholder {
      /* Firefox 18- */
      font-size: 20px;
      font-style: italic; }
  .form_field_wrapper input[type="image"] {
    background: transparent;
    border: 0 none;
    color: #373737;
    cursor: pointer;
    float: left;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    width: 55px; }
  .form_field_wrapper input[type="email"] {
    background: transparent;
    border: 0 none;
    color: #b5b5b5;
    font-size: 20px;
    height: 38px;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
  .form_field_wrapper input[type="password"] {
    background: transparent;
    border: 0 none;
    color: #b5b5b5;
    font-size: 20px;
    height: 38px;
    margin: 0;
    padding: 0 5px;
    width: 90%; }
  .form_field_wrapper input[type="submit"] {
    background: transparent;
    border: 0 none;
    color: #373737;
    font-weight: bold;
    height: 38px;
    outline: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 49px; }

#social_media {
  display: flex;
  padding: 0; }
  #social_media:after {
    clear: both;
    content: "";
    display: block; }

.social_icon {
  display: block;
  float: left;
  margin: 0 4px;
  width: 35px;
  height: 35px; }
  .social_icon#linkedin {
    background: url("../images/linkedin-icon.png") 0 0 no-repeat;
    background-size: 35px 35px; }
  .social_icon#facebook {
    background: url("../images/fb-icon.jpg") 0 0 no-repeat;
    background-size: 35px 35px; }
  .social_icon#twitter {
    background: url("../images/twitter-icon.jpg") 0 0 no-repeat;
    background-size: 35px 35px; }
  .social_icon#instagram {
    background: url("../images/instagram-icon.jpg") 0 0 no-repeat;
    background-size: 35px 35px; }
  .social_icon#youtube {
    background: url("../images/youtube-icon.png") 0 0 no-repeat;
    background-size: 35px 35px; }
  .social_icon:first-child {
    margin-left: 0; }
  .social_icon:last-child {
    margin-right: 0; }

#logo,
.logo {
  background: #fff;
  padding: 20px 48.4px; }
  #logo img,
  .logo img {
    display: block;
    margin: 0 auto;
    width: 100%; }

#home_content {
  background: #e7e7e7;
  padding: 15px 49px; }
  #home_content .scroll {
    height: 100%;
    position: relative;
    width: 100%; }
    #home_content .scroll img.scroll-img {
      height: auto;
      width: 100%; }
    #home_content .scroll .scroll-overlay {
      background: rgba(0, 0, 0, 0.4);
      left: 0;
      position: absolute;
      bottom: 0;
      padding: 5px 10px; }
      #home_content .scroll .scroll-overlay p {
        color: #fff;
        font-size: 14px;
        line-height: 1.3;
        margin: 0; }

#main {
  float: right;
  min-height: 400px;
  width: 932px; }
  #main:after {
    clear: both;
    content: "";
    display: block; }

#main.inside {
  bottom: 100%;
  padding: 10px 20px;
  min-height: 470px; }

#header_graphic {
  max-height: 290px;
  overflow: hidden;
  margin-left: -11px; }
  #header_graphic img {
    width: 100%;
    height: auto; }

#content {
  display: flex;
  flex-direction: row;
  align-items: stretch; }

#content.wide {
  display: block; }

#main_content {
  padding: 30px 33px 30px 43px; }
  #main_content img:not(.news_thumb) {
    -webkit-filter: brightness(1);
    filter: brightness(1);
    max-width: 100%;
    height: auto;
    transition: -webkit-filter 0.5s linear, filter 0.5s linear; }
    #main_content img:not(.news_thumb):hover {
      -webkit-filter: brightness(1);
      filter: brightness(1.4);
      transition: -webkit-filter 0.5s linear, filter 0.5s linear; }

.text-danger {
  color: #A94442; }
  .text-danger p {
    color: #A94442; }

.text-success {
  color: #3C763D; }
  .text-success p {
    color: #3C763D; }

#wide {
  min-height: 400px;
  background-color: #fff;
  padding: 10px 20px; }

#hotlinks {
  position: relative;
  z-index: 200; }

.hotlink img {
  height: auto;
  width: 100%; }

.address {
  color: #606060;
  font-size: 15px;
  line-height: 1.3; }
  .address .address_hdr {
    font-size: 20px; }
  .address .address_email a,
  .address .address_phone {
    color: #606060;
    font-size: 17px;
    font-weight: bold;
    margin: 0; }
  .address .address_email a:hover {
    color: #797979;
    text-decoration: none; }

#footer_top {
  background: #fff; }

.footer_login {
  padding: 0; }

.footer_form:last-child,
.footer_login {
  border-left: 2px solid #cfcfcf; }

.footer_form {
  padding: 0 49px 0 48px; }
  .footer_form .form_title {
    font-size: 14px;
    font-weight: bold;
    margin: 0; }
  .footer_form .form_field_wrapper:first-child {
    margin-bottom: 6px; }
  .footer_form input[type="email"]::-webkit-input-placeholder,
  .footer_form input[type="password"]::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 16px;
    font-weight: bold; }
  .footer_form input[type="email"]::-moz-placeholder,
  .footer_form input[type="password"]::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 16px;
    font-weight: bold; }
  .footer_form input[type="email"]:-ms-input-placeholder,
  .footer_form input[type="password"]:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 16px;
    font-weight: bold; }
  .footer_form input[type="email"]:-moz-placeholder,
  .footer_form input[type="password"]:-moz-placeholder {
    /* Firefox 18- */
    font-size: 16px;
    font-weight: bold; }
  .footer_form input[type="submit"] {
    font-weight: bold; }

.footer_form:first-child .form_title {
  color: #211d1e; }

.footer_form:first-child input[type="email"]::-webkit-input-placeholder,
.footer_form:first-child input[type="password"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a4a5a5; }

.footer_form:first-child input[type="email"]::-moz-placeholder,
.footer_form:first-child input[type="password"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #a4a5a5; }

.footer_form:first-child input[type="email"]:-ms-input-placeholder,
.footer_form:first-child input[type="password"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #a4a5a5; }

.footer_form:first-child input[type="email"]:-moz-placeholder,
.footer_form:first-child input[type="password"]:-moz-placeholder {
  /* Firefox 18- */
  color: #a4a5a5; }

.footer_form:first-child a {
  color: #211d1e; }

.footer_form:last-child .form_title {
  color: #2672b1; }

.footer_form:last-child input[type="email"]::-webkit-input-placeholder,
.footer_form:last-child input[type="password"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #94c4ec; }

.footer_form:last-child input[type="email"]::-moz-placeholder,
.footer_form:last-child input[type="password"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #94c4ec; }

.footer_form:last-child input[type="email"]:-ms-input-placeholder,
.footer_form:last-child input[type="password"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #94c4ec; }

.footer_form:last-child input[type="email"]:-moz-placeholder,
.footer_form:last-child input[type="password"]:-moz-placeholder {
  /* Firefox 18- */
  color: #94c4ec; }

.footer_form:last-child input[type="submit"] {
  color: #2672b1; }

.footer_form:last-child a {
  color: #1275b4; }

#footer {
  min-height: 50px;
  padding-top: 10px;
  background: #231f20; }
  #footer a {
    color: #000; }

#footer_text {
  font-size: 12px;
  float: right;
  padding: 0 11px;
  position: relative;
  text-align: right;
  width: 50%; }

#footer_nav {
  margin: 0;
  float: left;
  width: 50%; }
  #footer_nav li {
    list-style-type: none;
    display: inline-block; }
    #footer_nav li a {
      color: #fff;
      font-weight: bold;
      padding: 0 15px; }
      #footer_nav li a:hover {
        text-decoration: none; }

#adminlinks {
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 35px; }
  #adminlinks a {
    color: #fff; }
    #adminlinks a:hover {
      text-decoration: none; }

#webdevelopment a {
  color: #fff;
  display: inline;
  visibility: visible; }
  #webdevelopment a:hover {
    text-decoration: none; }

#navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-wrap: wrap; }

#nav_icon {
  display: none; }

#nav {
  margin: 0;
  overflow: visible;
  padding: 0; }

#nav li.top {
  cursor: pointer;
  display: block;
  float: left;
  font-size: 1em;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0; }

#nav li a.top_link,
#nav li span.top_link {
  color: #6e6e6e;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 1.7vw;
  margin: 0;
  padding: 24px 2vw;
  position: relative;
  text-decoration: none;
  transition: color 0.3s linear; }

#nav li.selected a.top_link,
#nav li.selected span.top_link #nav li:hover a.top_link,
#nav li:hover span.top_link,
#nav li.hover span.top_link {
  color: #297cc0;
  transition: color 0.3s linear; }

/* Default list styling */
#nav li:hover {
  position: relative;
  z-index: 400; }

#nav li:hover > ul.subnav {
  background-color: #14699F;
  border: 0;
  height: auto;
  left: 0;
  overflow: visible;
  top: 32px;
  white-space: nowrap;
  width: auto;
  z-index: 400; }

#nav li:hover > ul.subnav li {
  background-color: #14699F;
  display: block;
  font-weight: normal;
  line-height: 12px;
  margin: 0;
  position: relative;
  width: 100%; }

#nav li:hover > ul.subnav li a {
  border-top: 1px solid #1191D0;
  color: #fff;
  display: block;
  font-size: 10pt;
  font-weight: bold;
  margin: 0;
  padding: 8px;
  text-align: left;
  text-decoration: none;
  z-index: 700px; }

#nav li:hover > ul.subnav li a:hover {
  color: #F4E41F;
  text-decoration: none; }

#nav li:hover li:hover ul,
#nav li:hover li:hover li:hover ul,
#nav li:hover li:hover li:hover li:hover ul,
#nav li:hover li:hover li:hover li:hover li:hover ul {
  background: #14699F;
  height: auto;
  left: 100%;
  overflow: visible;
  top: 0;
  white-space: nowrap;
  width: auto;
  z-index: 400; }

#nav ul,
#nav li:hover ul ul,
#nav li:hover li:hover ul ul,
#nav li:hover li:hover li:hover ul ul,
#nav li:hover li:hover li:hover li:hover ul ul {
  height: 0;
  left: -9999px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -9999px;
  width: 0; }

ul.subnav {
  display: none; }

#sidebar {
  box-shadow: 0 3px 2px 2px rgba(0, 0, 0, 0.3);
  padding: 0;
  background: #e7e7e7;
  order: 2;
  z-index: 199; }

#sidenav {
  background: #e7e7e7;
  padding: 0 49px 5px;
  width: 100%; }

.sidenav {
  border-bottom: 1px solid #ccc;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: visible; }
  .sidenav li.top {
    padding: 0 6px;
    margin: 0; }
    .sidenav li.top:first-child a.top_link {
      border-top: 0 none; }
  .sidenav a.top_link {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #f5f5f5;
    color: #666;
    display: block;
    padding: 8px;
    text-transform: uppercase; }
    .sidenav a.top_link:hover {
      color: #6cb9f8;
      text-decoration: none; }
  .sidenav li.noLink {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #f5f5f5;
    color: #666;
    display: block;
    line-height: 1;
    padding: 8px;
    text-transform: uppercase; }
  .sidenav li.level2 {
    height: 0;
    overflow: hidden; }
    .sidenav li.level2 a.top_link {
      border: 0 none;
      font-size: 18px;
      padding: 4px 8px 4px 20px;
      text-transform: none; }
  .sidenav li.level3 {
    height: 0;
    overflow: hidden; }
    .sidenav li.level3 a.top_link {
      border: 0 none;
      font-size: 18px;
      padding: 4px 8px 4px 40px;
      text-transform: none; }
  .sidenav li.showNav {
    height: auto; }
    .sidenav li.showNav a {
      color: #83beee; }
    .sidenav li.showNav#news {
      min-height: 34px;
      margin: 0;
      width: 100%; }
  .sidenav li.top a.selected {
    color: #297cc0;
    border: 0 none; }

#home_events {
  padding: 0 49px 10px; }
  #home_events.inside {
    padding: 50px 49px 10px;
    background: #e7e7e7; }
  #home_events h3 {
    border-bottom: 1px solid #d3d3d3;
    color: #6e6e6e;
    font-size: 1.4em;
    font-weight: normal;
    letter-spacing: 7px;
    margin-bottom: 10px;
    padding-bottom: 5px; }
    #home_events h3 a {
      text-transform: uppercase;
      color: #6e6e6e; }
  #home_events .view_all {
    color: #999;
    font-size: 14px; }
    #home_events .view_all:hover {
      text-decoration: none; }
  #home_events .home_event {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 15px; }
    #home_events .home_event h4 {
      font-size: 18px;
      margin: 0; }
    #home_events .home_event .evt_title, #home_events .home_event .learn_more {
      color: #4072AB;
      font-size: 18px;
      margin: 0; }
    #home_events .home_event .evt_title:hover, #home_events .home_event .learn_more:hover {
      color: #6cb9f8;
      text-decoration: none; }
    #home_events .home_event .evt_date {
      color: #4072AB;
      font-size: 14px;
      font-weight: bold;
      margin: 0; }
    #home_events .home_event .evt_summary {
      font-size: 0.875em;
      line-height: 1.3;
      margin: 8px 0 0; }
    #home_events .home_event .learn_more {
      font-size: 11px; }

.tooltip-inner {
  background: #E0F0FA;
  text-align: left;
  padding-top: 7px;
  border: 1px solid #0E5FA3; }
  .tooltip-inner p {
    color: #0E5FA3;
    font-size: 0.875em;
    margin: 0;
    padding: 2px; }

.tooltip.top .tooltip-arrow {
  border-top-color: #0E5FA3; }

.tooltip_title p {
  color: #0E5FA3;
  margin: 0;
  font-weight: bold;
  font-size: 1.1em; }

.tooltip_body {
  margin-bottom: 5px; }
  .tooltip_body:last-child {
    border-bottom: 0 none; }

.nav_col {
  float: left;
  text-align: center;
  width: 33.33333333%; }

.tbl_prev {
  text-align: left; }

.tbl_next {
  text-align: right; }

.table {
  border-bottom: 1px solid #272324;
  display: table;
  margin: 0 auto 20px;
  max-width: 100%; }

.tbl_row {
  display: table-row; }
  .tbl_row:last-child {
    border-bottom: 1px solid #D0D0D0; }

.tbl_col {
  border-top: 1px solid #272324;
  border-left: 1px solid #272324;
  display: table-cell;
  width: 14.36%;
  height: 100px;
  padding: 5px; }
  .tbl_col:last-child {
    border-right: 1px solid #303538; }
  .tbl_col .date {
    display: none; }

.tbl_hdr {
  background: #272324;
  border-left: 1px solid #272324;
  border-right: 1px solid #6D6D6D;
  border-top: 1px solid #272324;
  display: table-cell;
  width: 14.36%; }
  .tbl_hdr:last-child {
    border-right: 1px solid #272324; }
  .tbl_hdr p {
    color: #94c4ec;
    font-size: 1.3em;
    font-weight: bold;
    margin: 0;
    padding: 5px 0;
    text-transform: uppercase;
    text-align: center; }

.event {
  background: #E0F0FA;
  border-top: 1px solid #0E5FA3;
  border-bottom: 1px solid #0E5FA3;
  margin-bottom: 5px;
  padding: 2px 5px; }
  .event a {
    display: block; }

.no_events p {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding: 10px 0; }

@media only screen and (max-width: 768px) {
  .tbl_hdr {
    display: none; }
  .tbl_col {
    border-right: 1px solid #1F1E6D;
    display: block;
    float: none;
    height: auto;
    width: 100%; }
    .tbl_col p.date {
      background: #1F1E6D;
      color: #FFF;
      display: block;
      font-size: 20px;
      padding: 5px 0 5px 5px;
      text-align: center; }
    .tbl_col.empty {
      border: 0 none;
      display: none; }
  .event a {
    display: block; } }

.event-widget {
  float: right;
  min-height: 230px;
  text-align: center;
  z-index: 1;
  position: relative;
  font-size: 17px; }
  @media screen and (max-width: 800px) {
    .event-widget {
      width: 100%; } }
  .event-widget__title {
    background: #0e273b; }
    .event-widget__title p {
      color: white; }
  .event-widget__main {
    background: #297cc0;
    display: flex; }
    @media screen and (max-width: 992px) {
      .event-widget__main {
        flex-direction: column; } }

.event__date {
  min-width: 100px;
  background: #297cc0; }
  @media screen and (max-width: 992px) {
    .event__date {
      border-bottom: 2px solid #0e273b; } }
  @media screen and (min-width: 992px) {
    .event__date {
      border-right: 2px solid #0e273b; } }
  .event__date p {
    color: white;
    font-weight: bold;
    text-transform: uppercase; }
    .event__date p span {
      font-size: 1.2em; }

.event__details {
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 30px 0; }
  .event__details p {
    color: white;
    margin-bottom: 0; }
  .event__details a {
    background: #0e273b;
    padding: 2px 25px;
    color: white;
    margin: 5px 0;
    transition: background-color 0.3s ease; }
    .event__details a:hover {
      background-color: #e7e7e7;
      color: #0e273b; }

.event__summary {
  background-color: #297cc0; }
  .event__summary p {
    color: white; }

.center-vh {
  display: flex;
  align-items: center;
  justify-content: center; }

.row.donate {
  padding: 0 0 30px; }

label.text_label.control-label {
  cursor: default;
  font-weight: 600;
  width: 100%; }

.form-group,
.form_buttons {
  padding: 0; }

.form-group-normal {
  padding: 0 11px; }

.form-group textarea {
  min-height: 200px; }

.checkbox label,
.radio label {
  width: 100%;
  padding-left: 25px; }

.radio input[type="radio"] {
  position: static;
  margin: 0 0 0 -15px; }

.radio .formTooltip {
  margin: 3px 0 0 10px; }

.radio > .row .group_wrapper {
  padding-left: 40px; }

.formTooltip > span {
  color: #297cc0; }

.form-tooltip-inner {
  max-width: 500px; }
  .form-tooltip-inner p {
    font-size: 1.1em; }

#map_canvas {
  min-height: 600px; }

.infowindow {
  height: 160px;
  width: 220px;
  overflow: hidden; }

.map_address,
.map_numbers {
  margin: 0; }

.map_link {
  margin: 10px 0 0; }

.infowindow p {
  font-size: 12px;
  margin: 0; }

.infowindow.hasImg {
  height: auto;
  min-width: 500px;
  max-width: 500px;
  width: auto; }

.infowindow.noImg {
  height: auto;
  width: auto;
  max-width: 220px; }

.infoImg {
  float: left;
  margin: 0 10px 10px 0; }

.infoImg.dataImg {
  cursor: pointer;
  float: right;
  margin: 0 0 10px 10px;
  position: relative;
  z-index: 200; }

.infoSummary {
  margin: 10px 0; }

.legend_icon {
  height: 32px;
  cursor: pointer; }

.legend_icon span {
  display: inline-block;
  width: 32px;
  margin-right: 5px; }

.hideMarkers {
  -webkit-filter: opacity(0.2) grayscale(50%);
  filter: opacity(0.2) grayscale(50%); }

.pledgebox {
  border: 1px solid black;
  background: #fff;
  transition: background 0.3s ease; }
  @media screen and (min-width: 992px) {
    .pledgebox {
      width: 250px; } }
  .pledgebox .pledgebox-hdr {
    background: #000;
    margin: 0 -11px; }
    .pledgebox .pledgebox-hdr p {
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      padding: 8px 0; }
  .pledgebox #pledgeCounter {
    font-size: 2em;
    font-weight: bold;
    display: block;
    text-align: center; }

.pledgebox-widget {
  padding: 0 49px 10px; }

.home-pledgebox {
  width: auto; }
  .home-pledgebox .pledgebox-hdr {
    margin: 0; }
  .home-pledgebox p {
    font-size: 18px; }
    @media screen and (min-width: 1025px) {
      .home-pledgebox p {
        font-size: calc(-12.07692px + 2.15385vw); } }
    @media screen and (min-width: 1350px) {
      .home-pledgebox p {
        font-size: 18px; } }
  .home-pledgebox #pledgeCounter {
    font-size: 36px; }
    @media screen and (min-width: 1025px) {
      .home-pledgebox #pledgeCounter {
        font-size: calc(-24.15385px + 4.30769vw); } }
    @media screen and (min-width: 1350px) {
      .home-pledgebox #pledgeCounter {
        font-size: 36px; } }

#pwd_text #random_pwd_wrapper {
  padding: 0;
  width: 260px; }
  @media screen and (min-width: 992px) {
    #pwd_text #random_pwd_wrapper {
      margin-left: 10px; } }

#captcha_img {
  max-width: 260px; }
  #captcha_img img {
    min-width: 260px; }

.clean-water-pledge > p {
  padding: 0 11px;
  width: 100%;
  float: left; }
  @media screen and (min-width: 768px) {
    .clean-water-pledge > p {
      width: 50%; } }
  @media screen and (min-width: 992px) {
    .clean-water-pledge > p {
      width: 66%; } }
  @media screen and (min-width: 1500px) {
    .clean-water-pledge > p {
      width: 75%; } }

.bonus-instructions {
  clear: both;
  padding: 50px 0; }
  .bonus-instructions p:first-child {
    color: #297cc0; }

.largeText {
  font-size: 3em; }

#group_21 .form-group {
  padding: 0 11px; }

ul.form_wrapper label.display_block {
  display: block;
  font-size: 1em; }

.block .errmsg {
  text-align: center;
  bottom: 25px; }

.login {
  position: relative;
  margin: 0 auto;
  padding: 20px 20px 20px;
  width: 310px;
  background-color: #fff; }

.login p.submit {
  text-align: center; }

.login-help {
  margin: 20px 0;
  font-size: 12px;
  color: #555;
  text-align: center;
  text-shadow: 0 1px #E1E1E1; }

.login-help a {
  color: #527881;
  text-decoration: none; }

.login-help a:hover {
  text-decoration: underline; }

:-moz-placeholder {
  color: #c9c9c9 !important;
  font-size: 13px; }

::-webkit-input-placeholder {
  color: #ccc;
  font-size: 13px; }

.login:before {
  content: '';
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  /*z-index: -1;*/
  background: rgba(0, 0, 0, 0.08);
  border-radius: 4px; }

.login h1 {
  margin: -20px -20px 21px;
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #555;
  text-align: center;
  text-shadow: 0 1px white;
  background: #f3f3f3;
  border-bottom: 1px solid #cfcfcf;
  border-radius: 3px 3px 0 0;
  background-image: -webkit-linear-gradient(top, whiteffd, #eef2f5);
  background-image: -moz-linear-gradient(top, whiteffd, #eef2f5);
  background-image: -o-linear-gradient(top, whiteffd, #eef2f5);
  background-image: linear-gradient(to bottom, whiteffd, #eef2f5);
  -webkit-box-shadow: 0 1px whitesmoke;
  box-shadow: 0 1px whitesmoke; }

.login h2 {
  color: #515151;
  font-size: 1.5em;
  text-align: center; }

.login p {
  margin: 20px 0 0; }

.login p:first-child {
  margin-top: 0; }

.login ul.form_wrapper {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  z-index: 2; }

.login ul.form_wrapper input[type=text], .login ul.form_wrapper input[type=password] {
  width: 260px; }

.login ul.form_wrapper input {
  font-family: 'Lucida Grande', Tahoma, Verdana, sans-serif;
  font-size: 14px; }

.login ul.form_wrapper li {
  margin: 0 0 10px; }

.login ul.form_wrapper input[type=text], .login ul.form_wrapper input[type=password] {
  margin: 5px;
  padding: 0 10px;
  height: 34px;
  color: #404040;
  background: white;
  border: 1px solid;
  border-color: #c4c4c4 #d1d1d1 #d4d4d4;
  border-radius: 2px;
  outline: 5px solid #eff4f7;
  -moz-outline-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12); }

.login ul.form_wrapper input[type=text]:focus, .login ul.form_wrapper input[type=password]:focus {
  border-color: #7dc9e2;
  outline-color: #dceefc;
  outline-offset: 0; }

.login ul.form_wrapper input[type=submit] {
  display: block;
  margin: 0 auto;
  padding: 0 18px;
  height: 29px;
  font-size: 12px;
  font-weight: bold;
  color: #527881;
  text-shadow: 0 1px #e3f1f1;
  background: #cde5ef;
  border: 1px solid;
  border-color: #b4ccce #b3c0c8 #9eb9c2;
  border-radius: 16px;
  outline: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-image: -webkit-linear-gradient(top, #edf5f8, #cde5ef);
  background-image: -moz-linear-gradient(top, #edf5f8, #cde5ef);
  background-image: -o-linear-gradient(top, #edf5f8, #cde5ef);
  background-image: linear-gradient(to bottom, #edf5f8, #cde5ef);
  box-shadow: inset 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.15); }

.login ul.form_wrapper input[type=submit]:active {
  background: #cde5ef;
  border-color: #9eb9c2 #b3c0c8 #b4ccce;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2); }

.list-group-item {
  background-color: rgba(0, 0, 0, 0); }

#news {
  min-height: 200px;
  margin-left: 7px;
  width: 455px; }
  #news h1 a {
    color: #565656; }

.news_block p {
  font-size: 1em;
  line-height: 1.2; }

.news_title {
  margin: 0;
  font-size: 1em; }

span.read_more {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 10px;
  width: 50%; }

.read_more a {
  color: #6E1720; }

.rss_link {
  display: inline-block;
  margin: -10px 0 0 0;
  text-align: right;
  width: 49%; }

.archives div {
  padding-right: 5px;
  margin-right: 5px; }

.archives:after {
  clear: both;
  content: "";
  display: block; }

.archive_year {
  width: 25%; }
  .archive_year p {
    margin: 0; }

#article_img {
  float: right;
  position: relative;
  z-index: 1; }
  #article_img > span.overlay {
    background: #000;
    color: #FFF;
    font-size: 4em;
    height: 95%;
    left: 10px;
    position: absolute;
    top: 0;
    opacity: 0;
    padding: 25% 0 0 41%;
    transition: opacity 0.25s linear;
    width: 97%; }
  #article_img:hover > span.overlay {
    opacity: 0.6;
    transition: opacity 0.5s linear; }

.detailCredits {
  padding: 0 0 4px 0; }

.detailDate {
  font-style: italic;
  margin: 0 0 5px 0; }

.record_listing:after {
  clear: both;
  content: "";
  display: block; }

#inner_search_container {
  height: 43px;
  margin-bottom: 10px; }

#inner_search {
  background: #fff;
  border: 1px solid #b5b5b5;
  border-radius: 0;
  color: #b5b5b5;
  font-size: 18px;
  float: left;
  height: 43px;
  margin: 0;
  outline: none;
  padding: 0 5px;
  width: 50%; }

#inner_search_btn {
  background: #2978bb;
  border: 0 none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  float: left;
  line-height: 1.1;
  padding: 9px 12px 8px; }

.searchResultsHdr {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 12px; }

.searchResult a {
  font-weight: bold; }

.searchResult > span {
  font-weight: bold; }

.grayItalic {
  color: #a1a1a1;
  font-style: italic;
  font-weight: normal; }

.searchType {
  color: #a1a1a1;
  font-weight: normal;
  text-transform: uppercase; }

.searchIcon {
  color: #a1a1a1; }

.hilitedTitle {
  border-bottom: 2px solid #000; }

.hilitedSummary {
  border-bottom: 2px solid #000;
  font-weight: normal; }

#slideshow {
  overflow: hidden;
  min-height: 100%; }

#slideshow img {
  height: 100%;
  min-height: 100%;
  min-width: 100%; }

.slide {
  position: relative;
  width: 100%; }

.cycle-overlay {
  font-family: tahoma, arial;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 15px;
  transition: bottom 0.1s linear 0.5s; }

.hide-overlay {
  bottom: -110px;
  transition: bottom 0.2s linear; }

.cycle-overlay div {
  color: white;
  font-size: 22px; }

.cycle-overlay div p {
  color: white;
  font-size: 17px;
  line-height: 1.1; }

.slide_nav {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  cursor: pointer;
  display: block;
  height: 100%;
  padding-top: 25%;
  position: absolute;
  top: 0;
  width: 10%;
  z-index: 100; }

.slide_nav > span {
  font-size: 2em;
  color: #fff; }

.cycle-prev {
  left: -10%;
  padding-left: 35px;
  transition: left 0.25s linear; }

.cycle-next {
  right: -10%;
  padding-right: 35px;
  text-align: right;
  transition: right 0.25s linear; }

#slideshow:hover .cycle-prev {
  left: 0;
  transition: left 0.25s linear; }

#slideshow:hover .cycle-next {
  right: 0;
  transition: right 0.25s linear; }

#teasers .teaser:nth-child(even) img {
  border-right: 1px solid #2672b1;
  border-left: 1px solid #2672b1; }

.teaser {
  padding: 20px 0; }

#main_content .gallery-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s; }

#main_content .gallery-item:hover .gallery-overlay {
  opacity: 1; }

#main_content .gallery-item:hover .gallery-item_details {
  top: 50%;
  left: 50%;
  opacity: 1; }

#main_content .gallery-item {
  position: relative;
  margin: auto;
  overflow: hidden;
  padding: 0;
  cursor: pointer; }
  #main_content .gallery-item_details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out 0s; }
    #main_content .gallery-item_details span {
      color: #fff;
      font-weight: 500;
      letter-spacing: 0.15em;
      margin-bottom: 0.5em;
      text-transform: uppercase;
      font-size: 20px; }
    #main_content .gallery-item_details p {
      color: #fff;
      font-size: 18px; }
  #main_content .gallery-item img {
    width: 100%;
    filter: none; }

.fadeIn-bottom {
  top: 80%; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  transition-duration: 0.3s;
  transition-property: transform; }

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  transform: scale(1.1); }

#cycleOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: black;
  opacity: .6;
  top: 0;
  z-index: 100;
  display: none; }

#gallerySlideShow {
  top: 25%;
  position: fixed;
  display: flex;
  justify-content: center; }

.gallerySlideShowContainer {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }

#slideNext, #slidePrevious, #slidePause, #slidePlay {
  position: absolute;
  font-size: 50px;
  z-index: 110;
  color: white;
  cursor: pointer;
  bottom: -15%; }

#slideNext {
  align-self: center;
  right: 0; }

#slidePrevious {
  left: 0;
  align-self: center; }

#slidePause, #slidePlay {
  align-self: flex-end;
  bottom: -15%; }

#slidePlay {
  display: none; }

.blur {
  filter: blur(20px); }

.cycle-caption {
  position: absolute;
  color: white;
  z-index: 110;
  font-size: 26px;
  top: -10%; }

#slideClose {
  position: absolute;
  top: -10%;
  color: white;
  z-index: 110;
  font-size: 41px;
  right: 0;
  cursor: pointer; }

@media screen and (max-width: 665px) {
  #gallerySlideShow img {
    width: 100%; } }

@media screen and (max-width: 1550px) {
  .lower_nav li a {
    font-size: 12px; } }

@media screen and (max-width: 1450px) {
  #home_content h3 {
    font-size: 1.5em; } }

@media screen and (max-width: 1400px) {
  .lower_nav li a {
    font-size: 10px; } }

@media screen and (max-width: 1340px) {
  .address {
    font-size: 10px; }
  .address .address_hdr {
    font-size: 15px; }
  .address .address_email a,
  .address .address_phone {
    font-size: 12px; } }

@media screen and (max-width: 1325px) {
  #home_content h3,
  #home_events h3 {
    font-size: 1.2em; } }

@media screen and (max-width: 1286px) {
  #nav li span.top_link,
  #nav li.top a.top_link {
    font-size: 1.5vw;
    padding: 24px 1.5vw; } }

@media screen and (max-width: 1280px) {
  #search_container,
  #sidebar {
    width: 20%; }
  #logo,
  #search_container {
    padding: 22px 29px; }
  #home_main,
  #main_inside,
  #navigation {
    width: 80%; }
  #home_content,
  #home_events,
  .pledgebox-widget {
    padding: 1px 29px 15px; } }

@media screen and (max-width: 1240px) {
  #home_content p {
    font-size: 1em; } }

@media screen and (max-width: 1024px) {
  #home_content h3 {
    font-size: 1.8em; }
  #content {
    display: block; }
  #search_container,
  #sidebar {
    box-shadow: none; }
  .social_icon {
    margin: 0 1px; }
  #navigation {
    width: 100%; }
  #search_container {
    width: 100%; }
  #slideshow img {
    width: 100%;
    height: auto;
    min-height: 1px; }
  #home_main,
  #main_inside,
  #sidebar,
  .footer_addresses,
  .footer_login {
    float: none;
    margin: 0;
    width: 100%; }
  #main_inside {
    padding-right: 11px !important; }
  #home_events.inside,
  #sidenav {
    display: none; }
  .footer_addresses:after,
  .footer_login:after {
    clear: both;
    content: "";
    display: block; }
  .footer_form:last-child,
  .footer_login {
    border: 0 none; }
  .footer_login {
    padding-top: 30px; }
  .footer_form {
    padding: 0 11px 20px; } }

@media screen and (max-width: 991px) {
  .nav_box {
    display: none !important; }
  #home_main,
  #navigation,
  #search_container,
  #sidebar {
    width: 100%; }
  #search_container,
  #sidebar {
    margin: 0; } }

@media screen and (max-width: 790px) {
  .footer_form:first-child .form_title {
    font-size: 12px; } }

@media screen and (max-width: 768px) {
  #home_content,
  #home_events,
  #home_events,
  #home_events.inside,
  #logo,
  #main_content,
  #sidenav,
  .pledgebox-widget {
    float: none;
    padding-left: 11px;
    padding-right: 11px;
    width: 100%; }
  #search_container {
    padding: 32px 11px; }
  .group_wrapper {
    clear: both;
    padding: 0;
    float: none;
    width: 100%; }
  span#random_pwd_wrapper {
    padding: 0;
    margin-top: 10px; }
  #captcha_wrapper {
    padding-right: 0;
    float: none;
    width: 100%;
    clear: both; }
  #captcha_img {
    display: block;
    min-height: 58px; }
  #inner_search {
    width: 75%; }
  #inner_search_btn {
    float: right; }
  #graphs {
    right: -50%;
    width: 50%; } }

@media screen and (max-width: 767px) {
  #teasers .teaser:nth-child(even) img {
    border: 0 none; }
  #teasers .teaser:nth-child(even) {
    border-top: 1px solid #2672b1;
    border-bottom: 1px solid #2672b1; }
  .address {
    float: left;
    width: 33%;
    margin: 0 0 10px; }
  .footer_form:first-child .form_title {
    font-size: 14px; }
  .logo_wrapper,
  .sub_nav_wrapper {
    float: left; }
  .sub_nav_wrapper {
    width: 70%; }
    .sub_nav_wrapper div {
      width: 50%; }
  .logo_wrapper {
    width: 30%; } }

@media screen and (min-width: 620px) and (max-width: 1024px) {
  #header .nav_box.show .nav_hide {
    display: block; }
  #header .nav_box .subLink {
    font-size: 13px; }
  .lower_nav {
    min-height: 100px; } }

@media screen and (max-width: 665px) {
  .infoImg.dataImg {
    float: none;
    margin: 0 0 10px; }
  .infowindow.hasImg {
    min-width: 320px;
    max-width: 100%;
    width: 100%; } }

@media screen and (max-width: 630px) {
  #graphs {
    right: -100%;
    width: 100%; } }

@media screen and (max-width: 620px) {
  #navigation {
    min-height: 65px;
    position: relative; }
  #header .nav_box.show {
    display: none !important; }
  #social_media {
    height: 65px;
    padding: 15px 0;
    position: absolute;
    right: 5px;
    top: 0;
    width: 195px; }
  #nav_icon,
  ul.subnav {
    display: block; }
  #nav_button {
    color: #297cc0;
    font-size: 44px;
    padding: 10px 0; }
  .nav_text {
    font-family: Arial, sans-serif;
    font-weight: bold; }
  #nav {
    width: 100%;
    position: absolute;
    top: -9999px;
    left: 0;
    height: auto; }
  #nav.show_nav {
    clear: both;
    position: static;
    top: 0; }
  #nav li.top {
    float: none;
    display: block; }
  #nav li span.top_link,
  #nav li.top a.top_link {
    float: none;
    display: block;
    text-align: left;
    font-size: 22px;
    padding: 10px 0; }
  #nav li.top:hover a.top_link {
    color: #838383; }
  #nav li span.top_link:hover,
  #nav li.top a.top_link {
    color: #838383; }
  #top_left {
    padding-bottom: 20px;
    padding-left: 20px; }
  #nav li:hover > ul.subnav,
  #nav li:hover li:hover ul,
  #nav li:hover ul ul,
  #nav ul ul,
  #nav ul.subnav {
    background: none;
    position: static;
    top: 0;
    left: 0;
    height: auto;
    width: 100%; }
  #nav li:hover > ul.subnav li,
  #nav ul.subnav li {
    background: none;
    line-height: 30px;
    padding-left: 25px;
    margin: 0; }
  #nav li:hover > ul.subnav li a,
  #nav ul.subnav li a {
    border: 0 none;
    background: none;
    color: #838383;
    display: block;
    font-size: 22px;
    margin: 0;
    padding: 10px 0;
    text-align: left;
    text-decoration: none;
    z-index: 700;
    font-weight: lighter; }
  #nav li.top > ul.subnav li a:hover {
    color: #838383; } }

@media screen and (max-width: 530px) {
  .address {
    width: 50%; }
  #nav li span.top_link,
  #nav li.top a.top_link,
  #nav li:hover > ul.subnav li a,
  #nav ul.subnav li a {
    font-size: 15px;
    font-weight: bold; }
  #inner_search {
    width: 65%; }
  .end_wrapper,
  .start_wrapper {
    float: none;
    padding: 0 !important;
    text-align: left;
    width: 100%; } }

@media screen and (max-width: 420px) {
  .address {
    width: 100%; }
  #inner_search {
    width: 55%; } }

@media screen and (max-width: 350px) {
  #nav_icon {
    margin-top: 11px; }
  #nav_button {
    font-size: 30px; } }

.categories {
  margin: 10px 0 10px 0; }

.filter div {
  padding-left: 0px;
  padding-right: 0px; }

h1.detail-title {
  font-size: 1.5em; }

h2.detail-title {
  padding-top: 10px;
  color: #231f20;
  font-size: 1.6em; }

.resource-directory h2 {
  color: #231f20;
  font-size: 1.3em;
  padding-left: 0px !important; }

.resource-directory_category {
  padding-left: 0px;
  padding-right: 0px; }
  .resource-directory_category p {
    background: #cccccc;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 800;
    text-align: left;
    padding: 20px 0 5px 10px;
    margin: 0 3px 3px 0;
    color: #fff; }
    .resource-directory_category p:hover {
      background: #DDDDDD; }
    @media screen and (min-width: 540px) {
      .resource-directory_category p {
        font-size: 2em; } }
    @media screen and (min-width: 768px) {
      .resource-directory_category p {
        font-size: 1.6em; } }
    @media screen and (min-width: 992px) {
      .resource-directory_category p {
        font-size: 1.5em; } }
  .resource-directory_category.elementary-school p {
    background: #2777c0; }
    .resource-directory_category.elementary-school p:hover {
      background: #1d5a92; }
  .resource-directory_category.middle-school p {
    background: #24813e; }
    .resource-directory_category.middle-school p:hover {
      background: #195f2d; }
  .resource-directory_category.high-school p {
    background: #dd7c27; }
    .resource-directory_category.high-school p:hover {
      background: #bf6b22; }
  .resource-directory_category.college p {
    background: #3aaaa6; }
    .resource-directory_category.college p:hover {
      background: #2d8e8b; }
  .resource-directory_category.selected p {
    background: #dddddd; }
  .resource-directory_category.selected.elementary-school p {
    background: #0d81eb; }
  .resource-directory_category.selected.middle-school p {
    background: #0eae3b; }
  .resource-directory_category.selected.high-school p {
    background: #fa7909; }
  .resource-directory_category.selected.college p {
    background: #2dcfc9; }

.resource-detail h2 {
  color: #231f20;
  font-size: 1.3em; }

.resource-detail-text {
  flex: 0 1 50%;
  order: 1;
  font-size: 0.9em; }
  .resource-detail-text h6 {
    font-size: 1.5em; }

.resource-detail-link {
  height: 100%;
  text-decoration: none; }
  @media screen and (min-width: 414px) {
    .resource-detail-link {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; } }

.resource-detail-name {
  font-size: 1em; }
  @media screen and (min-width: 414px) {
    .resource-detail-name {
      flex: 0 1 50%;
      order: 1; } }
  @media screen and (min-width: 768px) {
    .resource-detail-name {
      font-size: 1.2em; } }

.resource-detail-location {
  color: #9c4a4d;
  font-size: 1em; }
  @media screen and (min-width: 414px) {
    .resource-detail-location {
      flex: 0 1 50%;
      order: 4; } }

.resource-detail-summary {
  font-size: 0.9em; }
  @media screen and (min-width: 414px) {
    .resource-detail-summary {
      order: 3;
      flex: 0 1 50%; } }
  @media screen and (min-width: 768px) {
    .resource-detail-summary {
      font-size: 1em; } }

@media screen and (min-width: 414px) {
  .resource-detail-category {
    order: 5;
    flex: 0 1 100%; } }

.resource-detail_image {
  /*align-self: center;*/
  margin: 40px 0; }
  @media screen and (min-width: 300px) {
    .resource-detail_image {
      order: 4;
      flex: 0 1 50%; } }
  @media screen and (min-width: 768px) {
    .resource-detail_image {
      padding-left: 10px; } }

.resource_detail {
  border: 1px solid #d5d5d5;
  margin: 20px 10px 25px;
  padding: 10px; }
  @media screen and (min-width: 1200px) {
    .resource_detail {
      padding: 10px 10px 10px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; } }
  .resource_detail-name {
    font-size: 1.5em;
    color: #4a858f;
    font-weight: 900;
    margin: 0 0 40px; }
    @media screen and (min-width: 540px) {
      .resource_detail-name {
        font-size: 2.4em; } }

.resource_content {
  padding: 10px 0; }
  @media screen and (min-width: 768px) {
    .resource_content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; } }
  @media screen and (min-width: 992px) {
    .resource_content {
      flex: 0 1 50%; } }

.resource_media {
  text-align: center;
  padding: 5px !important; }
  .resource_media img {
    width: 100%; }
  @media screen and (min-width: 768px) {
    .resource_media {
      flex: 0 1 33%; } }

.resource_description {
  padding: 20px 0 0 0; }
  .resource_description p {
    margin: 0; }

.resource-website {
  font-size: 1.1em;
  flex: 0 1 100%;
  margin: 0 5px; }

.resource-container {
  flex: 0 1 100%; }

.resource-panel-title {
  font-size: 1.3em;
  color: #013c4d; }
  .resource-panel-title > .fa {
    color: #013c4d; }

.resource-locations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .resource-locations-item {
    flex: 0 1 auto;
    padding: 15px; }
    .resource-locations-item span {
      font-size: 1.1em;
      color: #013c4d; }
    .resource-locations-item span.fa {
      color: #013c4d; }
  .resource-locations-title {
    margin: 0 0 10px;
    font-size: 1.3em;
    color: #013c4d;
    padding-right: 15px;
    word-wrap: break-word; }

.resource_phone.fa {
  color: #013c4d; }

.resource_phone a {
  font-family: 'Montserrat', sans-serif; }

.resource_weburl {
  font-family: 'Montserrat', sans-serif; }

.resource_contact {
  flex: 1 0 50%; }

.resource-contact {
  font-family: 'Montserrat', sans-serif; }
  .resource-contact strong {
    font-size: 1.3em; }
  .resource-contact-name span.fa {
    color: #013c4d; }
  .resource-contact-name strong {
    color: #013c4d;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em; }
  .resource-contact-phone span.fa {
    color: #013c4d; }
  .resource-contact-phone strong {
    color: #013c4d;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em; }
  .resource-contact-email span.fa {
    color: #013c4d; }
  .resource-contact-email strong {
    color: #013c4d;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em; }
  .resource-contact-email a {
    word-wrap: break-word; }

.resource_categories {
  flex: 1 0 50%;
  text-transform: capitalize; }

.resource-map-detail {
  flex: 0 0 50%; }

@media screen and (min-width: 992px) {
  #map_canvas.resource_map {
    height: 690px; } }

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .flex-container .resource {
    border: 1px solid #999;
    margin: 12px 0px;
    padding-top: 12px;
    padding-bottom: 12px; }
    @media screen and (min-width: 1200px) {
      .flex-container .resource {
        flex: 0 1 calc(50% - 6px);
        -ms-flex: 0 1 44%;
        margin: 12px 6px 0px 0px; } }
    .flex-container .resource img {
      width: 100%; }
    .flex-container .resource.entrepreneurs {
      border: 1px solid #40598d; }
    .flex-container .resource.finance-and-funding {
      border: 1px solid #488e43; }
    .flex-container .resource.community-and-culture {
      border: 1px solid #9c4a4d; }
    .flex-container .resource.talent-and-expertise {
      border: 1px solid #df900b; }
    .flex-container .resource.mentors-and-advisors {
      border: 1px solid #5faa8e; }
    .flex-container .resource.markets-and-opportunities {
      border: 1px solid #4a858f; }
    .flex-container .resource.spaces-and-places {
      border: 1px solid #013c4d; }

.ui-menu-item {
  list-style: none;
  padding: 5px 0 0; }
  .ui-menu-item:hover {
    cursor: pointer; }
  .ui-menu-item-wrapper {
    color: #013c4d !important;
    border: none !important;
    padding: 5px; }

.ui-widget-content {
  /*background: none !important;*/ }

.ui-helper-hidden-accessible div {
  display: none; }

/*# sourceMappingURL=maps/styles.css.map */
